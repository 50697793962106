<template>
  <b-row>
    <vue-confirm-dialog />
    <b-col md="12">
      <b-card
        title="Adicionar Permissão"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Obra :"
              label-for="obra"
            >
              <b-form-input
                id="obra"
                v-model="obra.nome"
                placeholder="Nome da obra"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Selecione o usuario :"
              label-for="encarregado"
            >
              <v-select
                v-model="encarregado"
                label="name"
                placeholder="Selecione o usuario"
                :options="opcoesencarregado"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              @click="SalvarPermissao()"
            >
              Adicionar Permissão
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col md="12">
      <b-card
        title="Lista de Permissões"
      >
        <b-row>
          <vue-confirm-dialog />
          <b-col
            cols="12"
          >
            <b-card-body>
              <b-row>
                <b-col
                  md="9"
                />
                <b-col md="3" />
              </b-row>
              <data-table
                :data="data"
                :columns="columns"
                @on-table-props-changed="reloadTable"
              >
                <tbody
                  slot="body"
                  slot-scope="{
                    // eslint-disable-next-line vue/no-template-shadow
                    data }"
                >
                  <tr
                    v-for="item in data"
                    :key="item.id"
                  >
                    <td
                      v-for="column in columns"
                      :key="column.name"
                    >
                      <data-table-cell
                        :value="item"
                        :name="column.name"
                        :meta="column.meta"
                        :comp="column.component"
                        :classes="column.classes"
                      />
                      <slot v-if="column.label === 'Ações'">
                        <b-button
                          variant="danger"
                          class="btn-icon rounded-circle"
                          @click="DeletarDados(item)"
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button>
                      </slot>
                    </td>
                  </tr>
                </tbody>
              </data-table>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>

import vSelect from 'vue-select'
import { BCol, BFormGroup, BRow } from 'bootstrap-vue'

export default {
  name: 'Permissoes',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      data: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      columns: [
        {
          label: 'Usuario',
          name: 'user',
          orderable: true,
        },
        {
          label: 'Permissão',
          name: 'role',
          orderable: true,
        },
        {
          label: 'Ações',
          orderable: false,
        },
      ],
      obra: '',
      encarregado: '',
      opcoesencarregado: [],
    }
  },
  created() {
    this.$loading(true)
    this.BuscarInformacoes()
    this.BuscarDados()
  },
  methods: {
    SalvarPermissao() {
      const obj = {
        user_id: this.encarregado.id,
        obra_id: this.obra.id,
      }
      this.$loading(true)
      this.$http.post('admin/obra_user', obj).then(resp => {
        localStorage.setItem('admin/obra_user', JSON.stringify(resp))
        this.reloadTable()
        this.$loading(false)
      })
    },
    BuscarInformacoes() {
      this.dados = JSON.parse(localStorage.getItem('admin/permissao'))
      this.obra = this.dados
      this.BuscarEncarregado()
    },
    async BuscarEncarregado() {
      await this.$http.get('admin/buscar_usuarios').then(contrato => {
        this.opcoesencarregado = contrato.data.data
      })
    },
    reloadTable(tableProps) {
      this.BuscarDados(this.url, tableProps)
    },
    async BuscarDados(options = this.tableProps) {
      await this.$http.get(`admin/user_obra/${this.dados.id}`, { params: options }).then(resp => {
        this.data = resp
      })
      this.$loading(false)
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    async DeletarDados(item) {
      this.$confirm(
        {
          message: 'Tem certeza que deseja Excluir?',
          button: {
            no: 'Não',
            yes: 'Sim',
          },
          /**
             * Callback Function
             * @param {Boolean} confirm
             */
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`admin/obra_user/${item.id}`)
              this.Notificacao('success', 'Obra Removida com sucesso')
              this.reloadTable()
            }
          },
        },
      )
    },
  },
}
</script>

<style scoped>

</style>
